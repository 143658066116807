import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Select from 'react-select';
import { SimpleAlert, formatPrice } from '../../../../rbts/helpers';
import { styleSelect } from '../../../../rbts/assets/ts/_utils';
import { NumericFormat } from 'react-number-format';
import { ProductsList } from '../sections/ProductsList';
import { useCreateNewDonation } from '../hooks/DonationsHook';
import { DonationsModel } from '../models/DonationsModel';
import Swal from 'sweetalert2';

type Props = {
  dayId: number;
  handleClose: () => void;
};

const DonationsForm: React.FC<Props> = ({ dayId, handleClose }) => {
  const optionsDonatiosTypeSelect = [
    {
      value: 'efectivo',
      label: 'Donativo - efectivo',
      type: 1,
    },
    {
      value: 'materia_prima',
      label: 'Donativo - insumo',
      type: 2,
    },
    {
      value: 'materia_prima',
      label: 'Compra (insumo)',
      type: 3,
    },
  ];
  const [typeSelected, setTypeSelected] = useState<any | []>(null);
  const [isEffective, setIsEffective] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = useState<any[]>([]);
  const [isDisabledBtnSave, setIsDisabledBtnSave] = useState<boolean>(false);
  const donationSchema = Yup.object().shape({
    tipo: Yup.string().required('Este campo es requerido.'),
    descripcion: isEffective
      ? Yup.string().required('Este campo es requerido.')
      : Yup.string(),
    monto: isEffective
      ? Yup.number()
          .required('Este campo es requerido.')
          .min(1, 'Este campo es requerido.')
      : Yup.number(),
  });

  const initValues: DonationsModel = {
    descripcion: '',
    tipo: '',
    id_dia: dayId,
    monto: 0,
  };
  const { insertData } = useCreateNewDonation();

  const DonationForm = useFormik({
    initialValues: initValues,
    validationSchema: donationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);

      if (!isEffective && !productsList.length) {
        setLoading(false);
        SimpleAlert(
          'Es necesario tener al menos un producto agregado.',
          3000,
          'error'
        );
      } else {
        let arr: any = [];

        try {
          if (isEffective) {
            const { monto } = values;
            arr.push(values);
            Swal.fire({
              title: '¿Confirmas el registro del donativo?',
              html: `
              <div className="text-gray-600">
              Se está por registrar un donativo en efectivo por <b>${formatPrice(
                monto ? monto : 0,
                'es-MX',
                'MXN',
                2,
                2
              )}</b>. Una vez confirmado, no podrás modificarlo.
Por favor, verifica que toda la información sea correcta antes de continuar. 
              </div>
              `,
              showCancelButton: true,
              padding: '2em',
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Aceptar',
              reverseButtons: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light me-3',
              },
            }).then(async (result) => {
              if (result.isConfirmed) {
                await insertData(arr);
                handleClose();
                setLoading(false);
              } else {
                setLoading(false);
              }
            });
          } else {
            productsList.map((item: any) => {
              let obj = {
                id_dia: item.id_dia,
                id_producto: item.isNew ? null : item.id,
                cantidad: item.cantidad,
                tipo: item.tipo,
                descripcion: item.descripcion,
                nombre_producto: item.nombre,
              };
              arr.push(obj);
            });

            await insertData(arr);
            handleClose();
            setLoading(false);
          }
        } catch (error) {
          console.log('error', error);
          setLoading(false);
        }
      }
    },
  });

  const handleSelectDonationType = (selectedOption: any) => {
    if (selectedOption) {
      const { value, type } = selectedOption;

      let isEff = type === 1 ? true : false;
      setIsEffective(isEff);

      setTypeSelected(selectedOption);
      DonationForm.setFieldValue('tipo', value);
    } else {
      DonationForm.setFieldValue('tipo', '');
      setTypeSelected(null);
      setIsEffective(false);
      setProductsList([]);
    }
  };

  // const updateProducts = (productos: any) => {
  //   const cleanedData = productos.filter((item: any) => item.id !== 0);

  //   const dataToInsert = cleanedData.map((item: any) => {
  //     item.tipo = typeSelected?.value;
  //     item.id_dia = dayId;
  //   });

  //   setProductsList(cleanedData);
  // };

  const updateProducts = (productos: any) => {
    const cleanedData = productos.filter((item: any) => item.id !== 0);

    const dataToInsert = cleanedData.map((item: any) => ({
      ...item,
      tipo: typeSelected?.value,
      id_dia: dayId,
    }));

    setProductsList(dataToInsert);
  };

  return (
    <form
      id="kt_account_form"
      className="form"
      action="#"
      noValidate
      onSubmit={DonationForm.handleSubmit}
    >
      <div className="row">
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex mt-5">
              <label className="form-label w-auto required">Tipo</label>
            </div>
            <Select
              options={optionsDonatiosTypeSelect}
              value={typeSelected}
              styles={styleSelect}
              placeholder={'Seleccione'}
              isSearchable={true}
              noOptionsMessage={() => {
                return <span>{'No se encontraron registros'}</span>;
              }}
              onChange={handleSelectDonationType}
              isClearable
              backspaceRemovesValue
            />
            {DonationForm.touched.tipo && DonationForm.errors.tipo && (
              <div className="fv-plugins-message-container text-danger w-100">
                <span role="alert">{DonationForm.errors.tipo}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {isEffective ? (
        <>
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex mt-5">
                  <label className=" form-label w-auto required">Monto</label>
                </div>
                <NumericFormat
                  placeholder="$0.00"
                  className="form-control"
                  thousandSeparator={true}
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onValueChange={(values: any) => {
                    const { floatValue } = values;
                    DonationForm.setFieldValue('monto', floatValue);
                  }}
                />
                {DonationForm.touched.monto && DonationForm.errors.monto && (
                  <div className="fv-plugins-message-container text-danger w-100 mt-3">
                    <span role="alert">{DonationForm.errors.monto}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="w-100 d-flex mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Descripción
                    </label>
                  </div>
                  <textarea
                    className="form-control mb-3 w-100"
                    data-kt-element="input "
                    rows={2}
                    style={{ resize: 'none' }}
                    placeholder=""
                    {...DonationForm.getFieldProps('descripcion')}
                  ></textarea>
                  {DonationForm.touched.descripcion &&
                    DonationForm.errors.descripcion && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">
                          {DonationForm.errors.descripcion}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {!isEffective && typeSelected ? (
        <div className="row mt-5">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <ProductsList
              data={productsList}
              onGetDataProducts={updateProducts}
            />
          </div>
        </div>
      ) : null}

      <div className="d-flex justify-content-end mt-10">
        <button
          type="submit"
          className="btn btn-primary"
          data-kt-users-modal-action="submit"
          disabled={loading || isDisabledBtnSave}
        >
          {!loading && <span className="indicator-label">Guardar</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Espere por favor...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
};
export { DonationsForm };
