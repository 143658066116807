import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NumericFormat } from 'react-number-format';
import {
  KTSVG,
  SimpleAlert,
  TableList,
  cleanPhoneNumber,
  formatPrice,
  maskPhoneNumber,
} from '../../../../../rbts/helpers';
import { useCancelTicket, useGetBalanceRecovery } from '../hooks';
import { TicketsActive, TicketsInactive } from '../models/BalanceRecoveryModel';

type Props = {};

const View: React.FC<Props> = ({}) => {
  const validationSearchSchema = Yup.object().shape({});

  const validationCancelSchema = Yup.object().shape({
    folio_anterior: Yup.string().required('Campo requerido'),
    folio_nuevo: Yup.string().required('Campo requerido'),
  });

  const endOfPageRef = useRef<HTMLDivElement>(null);

  const [ticketsActive, setTicketsActive] = useState<TicketsActive[]>([]);
  const [ticketsInactive, setTicketsInactive] = useState<TicketsInactive[]>([]);

  const { cancelTicket, loading: loadingCT, isSuccess } = useCancelTicket();

  const [filter, setFilter] = useState({
    telefono: '',
    telefono_format: '',
    folio: '',
    random: 0,
  });
  const [reload, setReload] = useState(Math.random() * 40);

  const { balanceRecovery, setBalanceRecovery, loading } =
    useGetBalanceRecovery(filter, reload);

  const FormSearch = useFormik({
    initialValues: {
      telefono: '',
      telefono_format: '',
      folio: '',
      random: 0,
    },
    validationSchema: validationSearchSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      const { telefono_format, folio } = values;
      if (telefono_format || folio) {
        setBalanceRecovery(null);
        FormCancel.resetForm();
        setFilter({ ...values, random: Math.random() * 40 });
      } else {
        SimpleAlert('Favor agrega un teléfono o ticketcash', 3000, 'error');
      }
    },
  });

  const FormCancel = useFormik({
    initialValues: {
      id: 0,
      folio_anterior: '',
      folio_nuevo: '',
    },
    validationSchema: validationCancelSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      cancelTicket(values);
    },
  });

  const columnsActive: any = [
    {
      id: 'folio',
      name: <div className="fw-bolder text-muted me-5">Folio</div>,
      selector: (row: any) => row.folio,
      sortable: true,
      width: '40%',
    },
    {
      id: 'saldo',
      name: <div className="fw-bolder text-muted me-5">Saldo</div>,
      selector: (row: any) => formatPrice(row.saldo, 'es-MX', 'MXN', 2, 2),
      sortable: true,
      width: '40%',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5">Cancelar</div>,
      cell: (row: any) => {
        return (
          <>
            <input
              className="form-check-input"
              name="radio_ticket"
              type="radio"
              onClick={async () => {
                setIdFolioTicket(row.id, row.folio);
              }}
            />
            {/* <div
              className="text-center cursor-pointer"
              onClick={() => {
                setIdFolioTicket(row.id, row.folio);
              }}
            >
              <KTSVG
                path="/media/icons/duotune/general/gen034.svg"
                className="svg-icon-1 svg-icon-danger"
              />
            </div> */}
          </>
        );
      },
      sortable: false,
      width: '20%',
    },
  ];

  const columnsInactive: any = [
    {
      id: 'folio_anterior',
      name: <div className="fw-bolder text-muted me-5">Folio anterior</div>,
      selector: (row: any) => row.folio_anterior,
      sortable: true,
      width: '50%',
    },
    {
      id: 'saldo',
      name: <div className="fw-bolder text-muted me-5">Folio nuevo</div>,
      selector: (row: any) => row.folio_nuevo,
      sortable: true,
      width: '50%',
    },
  ];

  const handlePhoneChange = (values: any) => {
    const valueNumber = Number(values.target.value.replace(/\s/g, ''));
    if (valueNumber) {
      const value = values.target.value.toString();
      const maskedPhoneNumber = maskPhoneNumber(value);
      FormSearch.setFieldValue('telefono', maskedPhoneNumber);
      FormSearch.setFieldValue(
        'telefono_format',
        cleanPhoneNumber(maskedPhoneNumber)
      );
    } else {
      FormSearch.setFieldValue('telefono', '');
      FormSearch.setFieldValue('telefono_format', '');
    }
  };

  const changeTicket = (e: any) => {
    if (e) {
      const { value } = e.target;
      FormSearch.setFieldValue('folio', value);
    } else {
      FormSearch.setFieldValue('folio', 0);
    }
  };
  const setIdFolioTicket = (id: string, folio: string) => {
    FormCancel.setFieldValue('folio_anterior', folio);
    FormCancel.setFieldValue('id', id);
  };

  useEffect(() => {
    if (balanceRecovery) {
      if (balanceRecovery.ticketsActivos) {
        setTicketsActive(balanceRecovery.ticketsActivos);
      }
      if (balanceRecovery.ticketsCancelados) {
        setTicketsInactive(balanceRecovery.ticketsCancelados);
      }
    }
  }, [balanceRecovery]);

  useEffect(() => {
    if (isSuccess) {
      FormCancel.resetForm();
      setReload(Math.random() * 40);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (FormCancel.values.id > 0) {
      if (FormCancel.values.id > 0 && endOfPageRef.current) {
        endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [FormCancel.values.id]);

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid">
          <div className="card mb-6 mb-xl-9">
            <div className="card-header border-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Recuperar saldo con teléfono o ticketcash
                </span>
              </h3>
            </div>
            <div className="card-body">
              <form
                id="formBoxDetail"
                className="form"
                action="#"
                noValidate
                onSubmit={FormSearch.handleSubmit}
              >
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto">Teléfono: </label>
                        <div className="w-auto">
                          {FormSearch.touched.telefono &&
                            FormSearch.errors.telefono && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {FormSearch.errors.telefono}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="w-100 d-flex">
                        <input
                          className="form-control w-300px"
                          value={FormSearch.values.telefono}
                          placeholder="Ingresa el teléfono"
                          prefix=""
                          onChange={handlePhoneChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto">
                          Ticketcash:{' '}
                        </label>
                        <div className="w-auto">
                          {FormSearch.touched.folio && FormSearch.errors.folio && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {FormSearch.errors.folio}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="w-100 d-flex">
                        <NumericFormat
                          placeholder="Ingrese no. ticketcash"
                          thousandSeparator={false}
                          value={FormSearch.values.folio}
                          allowLeadingZeros
                          decimalSeparator={'.'}
                          allowNegative={false}
                          decimalScale={0}
                          onChange={changeTicket}
                          className="form-control w-300px"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                    <div className="w-100 pe-5 mt-8">
                      <div className="w-100 d-flex">
                        <button
                          type="submit"
                          title="Iniciar día"
                          onClick={() => {}}
                          className="btn btn-primary w-200px ms-5"
                          disabled={loading}
                        >
                          {!loading && (
                            <span className="indicator-label">Buscar</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: 'block' }}
                            >
                              Espere por favor
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {balanceRecovery && (
            <>
              <div className="card mb-6 mb-xl-9">
                <div className="card-header border-0">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">
                      Información del cliente
                    </span>
                  </h3>
                </div>
                <div className="card-body">
                  <div className="row mb-5">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="d-flex">
                        <div className="w-25">
                          <div className="d-flex">
                            <label className="form-label w-auto">Nombre:</label>

                            <div className="fw-bold fs-6 text-gray-600 ms-5">
                              {balanceRecovery?.cliente?.nombre || 'Sin nombre'}
                            </div>
                          </div>
                        </div>
                        <div className="w-25">
                          <div className="d-flex">
                            <label className="form-label w-auto">
                              Teléfono:
                            </label>

                            <div className="fw-bold fs-6 text-gray-600 ms-5">
                              {maskPhoneNumber(
                                balanceRecovery?.cliente?.telefono ||
                                  'Sin teléfono'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="d-flex fs-6 fw-semibold align-items-center mb-5">
                        <div className="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                        <div className="text-gray-700 flex-grow-1 me-4">
                          Ticketcash activos
                        </div>
                      </div>
                      <TableList
                        data={ticketsActive}
                        columns={columnsActive}
                        minHeight={'300px'}
                      ></TableList>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="d-flex fs-6 fw-semibold align-items-center mb-5">
                        <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                        <div className="text-gray-700 flex-grow-1 me-4">
                          Ticketcash cancelados
                        </div>
                      </div>

                      <TableList
                        data={ticketsInactive}
                        columns={columnsInactive}
                        minHeight={'300px'}
                      ></TableList>
                    </div>
                  </div>
                </div>
              </div>
              {FormCancel.values.id > 0 && (
                <div className="card mb-6 mb-xl-9" ref={endOfPageRef}>
                  <div className="card-header border-0">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-800">
                        Cancelación de ticketcash
                      </span>
                    </h3>
                  </div>
                  <div className="card-body">
                    <form
                      id="formBoxDetail"
                      className="form"
                      action="#"
                      noValidate
                      onSubmit={FormCancel.handleSubmit}
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="w-100 pe-5">
                            <div className="w-100 d-flex">
                              <label className="required form-label w-auto">
                                Ticketcash anterior:{' '}
                              </label>
                              <div className="w-auto">
                                {FormCancel.touched.folio_anterior &&
                                  FormCancel.errors.folio_anterior && (
                                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                      <span role="alert">
                                        {FormCancel.errors.folio_anterior}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <input
                                type="text"
                                {...FormCancel.getFieldProps('folio_anterior')}
                                disabled
                                placeholder="Ingrese no. ticketcash"
                                className="form-control w-300px"
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="w-100 pe-5">
                            <div className="w-100 d-flex">
                              <label className="required form-label w-auto">
                                Ticketcash nuevo:{' '}
                              </label>
                              <div className="w-auto">
                                {FormCancel.touched.folio_nuevo &&
                                  FormCancel.errors.folio_nuevo && (
                                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                      <span role="alert">
                                        {FormCancel.errors.folio_nuevo}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <NumericFormat
                                placeholder="Ingrese no. ticketcash"
                                thousandSeparator={false}
                                allowLeadingZeros
                                decimalSeparator={'.'}
                                allowNegative={false}
                                decimalScale={0}
                                {...FormCancel.getFieldProps('folio_nuevo')}
                                className="form-control w-300px"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="w-100 pe-5 mt-8">
                            <div className="w-100 d-flex">
                              <button
                                type="submit"
                                title="Transferir saldo"
                                onClick={() => {}}
                                className="btn btn-success w-200px ms-5"
                                disabled={loadingCT}
                              >
                                {!loadingCT && (
                                  <span className="indicator-label">
                                    Transferir saldo
                                  </span>
                                )}
                                {loadingCT && (
                                  <span
                                    className="indicator-progress"
                                    style={{ display: 'block' }}
                                  >
                                    Espere por favor
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { View };
