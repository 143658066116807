import { FC } from 'react';
import { PageTitle, PageLink } from '../../../rbts/layout/core';
import { ViewAE } from './components/ViewAE';

const DonationsCreateEditPage: FC = () => {
  const widgetsBreadCrumbs: Array<PageLink> = [];

  return (
    <>
      <PageTitle
        breadcrumbs={widgetsBreadCrumbs}
      >{`${'Compras y donativos'}`}</PageTitle>
      <ViewAE />
    </>
  );
};

export { DonationsCreateEditPage };
