import React from 'react';
import { toAbsoluteUrl } from '../AssetHelpers';

type Props = {
  mensaje: string;
};

const MensajeDia: React.FC<Props> = ({ mensaje = '' }) => {
  return (
    <div className="d-flex flex-column flex-xl-row">
      <div className="flex-lg-row-fluid">
        <div
          className="card h-lg-100"
          style={{
            background: 'linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)',
          }}
        >
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-sm-7 pe-0 mb-5 mb-sm-0">
                <div className="d-flex justify-content-between h-100 flex-column pt-xl-5 pb-xl-2 ps-xl-7">
                  <div className="mb-7">
                    <div className="mb-6">
                      <h3 className="fs-2x fw-semibold text-white">
                        No se encontro un día administrativo iniciado.
                      </h3>
                      <span className="fw-semibold text-white opacity-100">
                        Inicia el día administrativo para habilitar las
                        funciones del sistema.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-5">
                <div
                  className="
                          d-flex
                          flex-row-auto
                          bgi-no-repeat
                          bgi-position-x-center
                          bgi-size-contain
                          bgi-position-y-bottom
                          min-h-100px min-h-lg-200px
                        "
                  style={{
                    backgroundImage: `url('${toAbsoluteUrl(
                      '/media/illustrations/sketchy-1/17-dark.png'
                    )}')`,
                  }}
                ></div>
                {/* <img src="/metronic8/demo1/assets/media/svg/illustrations/easy/7.svg" className="h-200px h-lg-250px my-n6" alt="">  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MensajeDia };
