import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { FallbackView } from '../../rbts/partials';
import { userHasRole } from '../../rbts/helpers';
import { UserModel } from '../modules/auth/models/UserModel';
import { RootState } from '../../setup';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { UsuariosPage } from '../pages/usuarios/Usuarios';
import { BoxDeliveryPage } from '../pages/treasury/box_delivery/BoxDelivery';
import { BoxCutPage } from '../pages/treasury/box_cut/BoxCut';
import { DonationsCreateEditPage } from '../pages/donations/DonationsAE';
import { SupplierAgreementsPage } from '../pages/shopping/supplierAgreements/SupplierAgreements';
import { SalesSupplierCreateEditPage } from '../pages/sales/Suppliers/SalesSupplierAE';
import { ConciliationPage } from '../pages/conciliation/Conciliation';
import { StarShiftPage } from '../pages/start_shift/StartShift';
import { EnlistmentPage } from '../pages/customer_service/enlistment/Enlistment';
import { RecoverBalancePage } from '../pages/customer_service/recover_balance/RecoverBalance';
import { CheckBalancePage } from '../pages/customer_service/check_balance/CheckBalance';
import { DayCutPage } from '../pages/treasury/day_cut/DayCut';
import { DayCutReportPage } from '../pages/reportes/day_cut/DayCut';

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* INICIO */}
        <Route
          path="/dashboard"
          component={
            userHasRole(['ADMIN', 'TESO', 'SERVCLIENTE', 'COMPRAS'], userRoles)
              ? DashboardWrapper
              : ErrorsPage
          }
        />
        {/* TESORERIA */}
        <Route
          path="/tesoreria/entrega_caja"
          component={
            userHasRole(['ADMIN', 'TESO'], userRoles)
              ? BoxDeliveryPage
              : ErrorsPage
          }
        />
        <Route
          path="/tesoreria/corte_caja"
          component={
            userHasRole(['ADMIN', 'TESO'], userRoles) ? BoxCutPage : ErrorsPage
          }
        />

        {/* SERVICIO CLIENTE */}
        <Route
          path="/servicio_cliente/enrolamiento"
          component={
            userHasRole(['ADMIN', 'TESO', 'SERVCLIENTE'], userRoles)
              ? EnlistmentPage
              : ErrorsPage
          }
        />
        <Route
          path="/servicio_cliente/recuperar_saldo"
          component={
            userHasRole(['ADMIN', 'TESO', 'SERVCLIENTE'], userRoles)
              ? RecoverBalancePage
              : ErrorsPage
          }
        />
        <Route
          path="/servicio_cliente/consultar_saldo"
          component={
            userHasRole(['ADMIN', 'TESO', 'SERVCLIENTE'], userRoles)
              ? CheckBalancePage
              : ErrorsPage
          }
        />

        {/* COMPRAS */}
        <Route
          path="/compras/acuerdos_proveedores/"
          component={
            userHasRole(['ADMIN', 'COMPRAS'], userRoles)
              ? SupplierAgreementsPage
              : ErrorsPage
          }
        />

        <Route
          path="/compras/ventas_proveedores/"
          component={
            userHasRole(['ADMIN', 'COMPRAS'], userRoles)
              ? SalesSupplierCreateEditPage
              : ErrorsPage
          }
        />
        <Route
          path="/compras/iniciar_turno/"
          component={
            userHasRole(['ADMIN', 'COMPRAS'], userRoles)
              ? StarShiftPage
              : ErrorsPage
          }
        />
        <Route
          path="/compras/conciliacion/"
          component={
            userHasRole(['ADMIN', 'COMPRAS'], userRoles)
              ? ConciliationPage
              : ErrorsPage
          }
        />

        {/* DONATIVOS */}
        <Route
          path="/compras/donativos/"
          component={
            userHasRole(['ADMIN'], userRoles)
              ? DonationsCreateEditPage
              : ErrorsPage
          }
        />

        <Route
          path="/tesoreria/cortes_dia/"
          component={
            userHasRole(['ADMIN'], userRoles) ? DayCutPage : ErrorsPage
          }
        />

        <Route
          path="/reportes/reporte_dia"
          component={
            userHasRole(['ADMIN'], userRoles) ? DayCutReportPage : ErrorsPage
          }
        />

        {/* <Route
          path="/catalogos/prueba/"
          component={userHasRole(['ADMIN'], userRoles) ? TestPage : ErrorsPage}
        />
        <Route
          path="/catalogos/generico/:code"
          component={
            userHasRole(['ADMIN'], userRoles) ? GenericoPage : ErrorsPage
          }
        /> */}
        <Route
          path="/usuarios"
          component={
            userHasRole(['ADMIN'], userRoles) ? UsuariosPage : ErrorsPage
          }
        />
        {/* <Route
          path="/empresas"
          component={
            userHasRole(['ADMIN'], userRoles) ? EmpresasPage : ErrorsPage
          }
        />
        <Route
          path="/api_keys/"
          component={
            userHasRole(['ADMIN'], userRoles) ? ApiKeyPage : ErrorsPage
          }
        /> */}
        <Route path="/perfil" component={ProfilePage} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
