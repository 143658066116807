import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { KTSVG, SimpleAlert } from '../../../../rbts/helpers';
import { useSelectProducts } from '../../sales/Suppliers/hooks/SalesSupplierHook';
import { styleSelect } from '../../../../rbts/assets/ts/_utils';

type Props = {
  data: any[];
  onGetDataProducts: (products: any) => void;
};

const ProductsList: React.FC<Props> = ({ data, onGetDataProducts }) => {
  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const { products, loadingRequest } = useSelectProducts();
  const [isNewProduct, setIsNewProduct] = useState<boolean>(false);
  const inputAmountRefs = useRef<any>([]);
  const inputsNameRefs = useRef<any>([]);
  const inputsDescRefs = useRef<any>([]);
  const inputNewProductRefs = useRef<any>([]);

  useEffect(() => {
    products.unshift({
      id: 0,
      value: 'new',
      label: 'Crear nuevo producto',
      data: [],
    });
  }, [products]);

  const productsSelected = (updatedDataMemo: any) => {
    onGetDataProducts(updatedDataMemo);
  };

  const AddRow = () => {
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: any = {
      id: 0,
      nombre: 'Ingresa el nombre del nuevo producto.',
      descripcion: 'Ingresa una breve descripción.',
      cantidad: 1,
      tipo: '',
      id_dia: '',
    };
    setDataMemo([...cleanedData, newRow]);
  };

  const addProduct = (selectedOption: any) => {
    if (selectedOption) {
      const {
        data: { id, nombre },
      } = selectedOption;

      if (id) {
        const cleanedData = dataMemo.filter((item) => item.id !== 0);
        const existingIndex = dataMemo.findIndex((item) => item.id === id);
        if (existingIndex === -1) {
          const updateRow = {
            id: id,
            nombre: nombre,
            cantidad: 1,
            descripcion: nombre,
            tipo: '',
            id_dia: '',
            isNew: false,
          };
          setDataMemo((prevDataMemo) => {
            const updatedDataMemo = [...prevDataMemo, updateRow];
            productsSelected(updatedDataMemo);
            return updatedDataMemo;
          });
          setDataMemo([...cleanedData, updateRow]);
        } else {
          setDataMemo([...cleanedData]);
          SimpleAlert(
            'Ya existe el producto en la orden actual.',
            3000,
            'error'
          );
        }
      } else {
        setIsNewProduct(!id ? true : false);
      }
    }
  };

  const deleteRowById = (productId: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.filter(
        (item) => item.id !== productId
      );
      productsSelected(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  useEffect(() => {
    if (data.length) {
      const cleanedData = data.filter((item) => item?.id !== 0);
      setDataMemo(cleanedData);
    }
  }, [data]);

  const handleAmountChange = (e: any, id: any) => {
    e.preventDefault();

    let amount = e.target.value.trim();
    if (amount === '') {
      amount = 1;
    } else {
      amount = parseFloat(amount);
    }

    const updatedItems = dataMemo.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          cantidad: amount,
        };
      }
      return item;
    });

    setDataMemo(updatedItems);
    productsSelected(updatedItems);
  };

  const handleNameProduct = (event: any) => {
    const newName = event.target.value;

    if (newName) {
      const cleanedData = dataMemo.filter((item) => item.id !== 0);
      const existingIndex = dataMemo.findIndex(
        (item) => item.nombre === newName
      );

      if (existingIndex === -1) {
        const count = Math.random() * 40;

        const newRow = {
          id: count,
          nombre: newName,
          cantidad: 1,
          descripcion: newName,
          tipo: '',
          id_dia: '',
          isNew: true,
        };

        const updatedDataMemo = [...dataMemo, newRow];
        setDataMemo(updatedDataMemo);
        setIsNewProduct(false);

        productsSelected(updatedDataMemo);
      } else {
        setDataMemo([...cleanedData]);
        setIsNewProduct(false);
        SimpleAlert('Ya existe el producto en la orden actual.', 3000, 'error');
      }
    } else {
      SimpleAlert('El nombre del producto es requerido', 3000, 'error');
    }
  };

  const handleOnBlurDescription = (e: any, id: number) => {
    const updatedItems = dataMemo.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          descripcion: e.target.value,
        };
      }
      return item;
    });

    setDataMemo(updatedItems);
    productsSelected(updatedItems);
  };

  const handleOnBlurNameNewProduct = (e: any, id: number) => {
    const updatedItems = dataMemo.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          nombre: e.target.value,
        };
      }
      return item;
    });

    setDataMemo(updatedItems);
    productsSelected(updatedItems);
  };

  useEffect(() => {
    if (inputsNameRefs && isNewProduct) {
      inputsNameRefs.current.focus();
    }
  }, [isNewProduct]);

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y w-100 mt-5">
        <h1 className="text-muted">Materia prima</h1>
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5 w-100">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-200px">Nombre</th>
              <th className="w-300px">Descripción</th>
              <th className="w-200px">Cantidad</th>
              <th className="w-100px"></th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <>
                  <tr key={row.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-primary">
                          {row.id === 0 ? (
                            isNewProduct ? (
                              <>
                                <input
                                  className="form-control"
                                  ref={inputsNameRefs}
                                  type="text"
                                  placeholder={row.nombre}
                                  onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                      event.preventDefault();
                                      handleNameProduct(event);
                                    }
                                  }}
                                />
                                <a
                                  className="btn btn-sm"
                                  title="Regresar a lista de productos"
                                  onClick={() => {
                                    setIsNewProduct(false);
                                  }}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/arrows/arr015.svg"
                                    className="svg-icon-1 svg-icon-primary"
                                  />
                                </a>
                              </>
                            ) : (
                              <Select
                                key={row.id}
                                options={products}
                                styles={styleSelect}
                                placeholder={'Seleccione'}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                noOptionsMessage={() => {
                                  return (
                                    <span>{'No se encontraron registros'}</span>
                                  );
                                }}
                                onChange={addProduct}
                                isLoading={loadingRequest}
                                backspaceRemovesValue
                              />
                            )
                          ) : (
                            <input
                              ref={(el: any) =>
                                (inputNewProductRefs.current[index] = el)
                              }
                              type="text"
                              className="form-control border-0 text-primary"
                              defaultValue={row.nombre}
                              readOnly={row.isNew ? false : true}
                              onBlur={(event) => {
                                event.preventDefault();
                                handleOnBlurNameNewProduct(event, row.id);
                              }}
                              onFocus={() => {
                                if (row.isNew)
                                  inputNewProductRefs.current[index].select();
                              }}
                            ></input>
                          )}
                        </div>
                      </div>
                    </td>

                    {row.id ? (
                      <>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-primary">
                              <input
                                className="form-control fs-7"
                                ref={(el: any) =>
                                  (inputsDescRefs.current[index] = el)
                                }
                                type="text"
                                defaultValue={row.nombre}
                                onBlur={(event) => {
                                  event.preventDefault();
                                  handleOnBlurDescription(event, row.id);
                                }}
                                onFocus={() => {
                                  inputsDescRefs.current[index].select();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <NumericFormat
                                placeholder="1"
                                defaultValue={row.cantidad}
                                getInputRef={(el: any) =>
                                  (inputAmountRefs.current[index] = el)
                                }
                                thousandSeparator={false}
                                decimalSeparator={'.'}
                                allowNegative={false}
                                decimalScale={1}
                                className="form-control w-100"
                                style={{ outline: 'none' }}
                                onChange={(e) => handleAmountChange(e, row.id)}
                                onFocus={() => {
                                  inputAmountRefs.current[index].select();
                                }}
                              />
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <a
                                className="badge badge-light-danger  text-hover-danger fw-bold px-4 py-3 cursor-pointer"
                                title="Eliminar producto"
                                onClick={async (event) => {
                                  deleteRowById(row.id);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/general/gen027.svg"
                                  className="svg-icon-4"
                                />
                              </a>
                            </div>
                          </div>
                        </td>
                      </>
                    ) : null}
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin productos
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Producto
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { ProductsList };
