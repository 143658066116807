import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DonationsModel } from "../models/DonationsModel";
import {
  createNewDonations,
  getAllDonations,
} from "../../../service/donations/Donations";
import { getAllDaysSelect } from "../../../service/box_delivery";
import { SimpleAlert } from "../../../../rbts/helpers";

export const useGetAllDonationsList = (realoadGrid: number) => {
  const [listDonations, setListDonations] = useState<DonationsModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllDonations()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setListDonations(res.rows);
        setLoadingRequest(false);
      }
    };

    fetchPost();
  }, [realoadGrid]);

  return { listDonations, loadingRequest };
};

export const useCreateNewDonation = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const insertData = async (body: DonationsModel) => {
    setLoadingInsert(false);
    try {
      await createNewDonations(body);
      setLoadingInsert(false);
      SimpleAlert(
        "Sé guardo la información correctamente.",
        3000,
        "success"
      );
    } catch (err: any) {
      setLoadingInsert(false);
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      SimpleAlert(resMessageToast, 3000, "error");
      throw resMessageToast;
    }
  };
  return { insertData, loadingInsert };
};

export const useGetAllDaysSelect = (reload?: number) => {
  const [catalog, setCatalog] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllDaysSelect()
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};
