import { FC } from 'react';
import { PageTitle, PageLink } from '../../../rbts/layout/core';
import { View } from './components/View';

const StarShiftPage: FC = () => {
  const widgetsBreadCrumbs: Array<PageLink> = [];

  return (
    <>
      <PageTitle
        breadcrumbs={widgetsBreadCrumbs}
      >{`${'Puntos de venta'}`}</PageTitle>
      <View />
    </>
  );
};

export { StarShiftPage };
