import React, { useState } from 'react';
import {
  LoadingGrid,
  TableList,
  formatPrice,
} from '../../../../../rbts/helpers';
import { useGetAllSalesSupplier } from '../hooks/SalesSupplierHook';
import { DetailSaleModal } from '../_modals/DetailSaleModal';

type Props = {
  filter: any;
};

const SalesSupplierGrid: React.FC<Props> = ({ filter }) => {
  const { data, loadingRequest } = useGetAllSalesSupplier(filter);
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const [saleId, setSaleId] = useState<number>(0);

  const dropdownRender = (data: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            className="btn btn-primary btn-sm"
            href="#/"
            onClick={() => {
              setShowModalDetail(!showModalDetail);
              const { id } = data;
              setSaleId(id);
            }}
          >
            Ver
          </a>
        </div>
      </>
    );
  };

  const columns: any = [
    // {
    //   id: "fecha",
    //   name: (
    //     <div className="fw-bolder text-muted me-5">Fecha administrativa</div>
    //   ),
    //   selector: (row: any) => row.dia,
    //   cell: (row: any) => (
    //     <>
    //       <span className="text-primary">
    //         {row.dia ? row.dia.fecha : "N/A"}
    //       </span>
    //     </>
    //   ),
    //   sortable: false,
    //   width: "20%",
    // },
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Punto de venta</div>,
      cell: (row: any) => (
        <>
          <span>
            {row.punto_venta_comprador
              ? row.punto_venta_comprador?.nombre
              : 'N/A'}
          </span>
        </>
      ),
      sortable: false,
      width: '40%',
    },
    {
      id: 'supplier',
      name: <div className="fw-bolder text-muted me-5">Proveedor</div>,
      selector: (row: any) => row.punto_venta_comprador,
      cell: (row: any) => (
        <>
          <span>No registrado</span>
        </>
      ),
      sortable: false,
      width: '30%',
    },
    {
      id: 'saldo',
      name: (
        <div className="fw-bolder text-muted me-5 ">Saldo Electrónico </div>
      ),
      selector: (row: any) => row.monto_total,
      cell: (row: any) => (
        <>
          <span className="text-success ">
            {formatPrice(
              row.monto_total ? row.monto_total : 0,
              'es-MX',
              'MXN',
              2,
              2
            )}
          </span>
        </>
      ),
      sortable: false,
      width: '20%',
    },

    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
      width: '10%',
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          {loadingRequest ? (
            <LoadingGrid text="Cargando..."></LoadingGrid>
          ) : (
            <TableList data={data} columns={columns}></TableList>
          )}
        </div>
      </div>
      <DetailSaleModal
        show={showModalDetail}
        saleId={saleId}
        handleClose={() => {
          setShowModalDetail(false);
          setSaleId(0);
        }}
      ></DetailSaleModal>
    </>
  );
};

export { SalesSupplierGrid };
