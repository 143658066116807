import React, { useState, useEffect } from 'react';
import { KTSVG, formatPrice } from '../../../../../rbts/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useGetSaleSupplierById } from '../hooks/SalesSupplierHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  saleId: number;
};

const DetailSaleModal: React.FC<Props> = ({ show, handleClose, saleId }) => {
  const { data, loadingRequest } = useGetSaleSupplierById(saleId);
  const [detail, setDetail] = useState<any>([]);

  useEffect(() => {
    if (data && saleId > 0) {
      setDetail(data);
    } else {
      setDetail([]);
    }
  }, [data]);

  useEffect(() => {
    if (!show) {
      setDetail([]);
    }
  }, [show]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{'Información de la venta'}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y  mt-5">
            {loadingRequest ? (
              <div className="w-100 text-center">
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Cargando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </div>
            ) : (
              <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 ">
                <thead>
                  <tr className="text-gray-800 fs-7">
                    <th className="w-300px">Nombre</th>
                    <th className="w-300px">Precio</th>
                    <th className="w-300px">Cantidad</th>
                    <th className="w-300px">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {detail && detail.detalle ? (
                    detail.detalle.map((item: any) => {
                      return (
                        <>
                          <tr key={data.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-primary">
                                  {item.producto?.nombre}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPrice(
                                    item.precio_unitario
                                      ? item.precio_unitario
                                      : 0,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {item.cantidad}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-primary">
                                  {`${formatPrice(
                                    item.precio_total ? item.precio_total : 0,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center text-muted">
                        Sin registros
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <th scope="row text-bold fs-4">Total</th>
                    <td className="text-success fs-4">
                      {' '}
                      {`${formatPrice(
                        detail.monto_total ? detail.monto_total : 0,
                        'es-MX',
                        'MXN',
                        2,
                        2
                      )}`}
                    </td>
                  </tr>
                </tfoot>
              </table>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { DetailSaleModal };
