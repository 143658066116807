import { FC } from 'react';
import { PageTitle, PageLink } from '../../../../rbts/layout/core';
import { ViewAE } from './components/ViewAE';

const SalesSupplierCreateEditPage: FC = () => {
  const widgetsBreadCrumbs: Array<PageLink> = [];

  return (
    <>
      <PageTitle
        breadcrumbs={widgetsBreadCrumbs}
      >{`${'Suministro a puntos de venta'}`}</PageTitle>
      <ViewAE />
    </>
  );
};

export { SalesSupplierCreateEditPage };
