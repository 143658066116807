import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  useBoxCut,
  useFinishDayDelivery,
  useGetAllCashierUserSelect,
  useGetAllDaysSelect,
  useGetAllPOSSelect,
  useGetAllShiftsSelect,
  useGetCutStarted,
  useGetInfoCut,
  useGetInfoPerUser,
} from '../../box_delivery/hooks';
import { styleSelect } from '../../../../../rbts/assets/ts/_utils';
import {
  LoadingGrid,
  TableList,
  formatDateString,
  formatPrice,
} from '../../../../../rbts/helpers';
import { initList } from '../models/CutInfoModel';
import { DetailModal } from '../_modals/DetailModal';
import { MensajeDia } from '../../../../../rbts/helpers/components/MensajeDia';

type Props = {};

const validationSchema = Yup.object().shape({
  // id_punto_venta: Yup.number()
  //   .min(1, 'Campo requerido')
  //   .required('Campo requerido'),
  // id_dia: Yup.number().min(1, 'Campo requerido').required('Campo requerido'),
  // id_turno: Yup.number().min(1, 'Campo requerido').required('Campo requerido'),
  // id_usuario_encargado: Yup.number()
  //   .min(1, 'Campo requerido')
  //   .required('Campo requerido'),
  // efectivo_apertura: Yup.number()
  //   .min(1, 'Campo requerido')
  //   .required('Campo requerido'),
});
const validationSearchSchema = Yup.object().shape({
  id_usuario_encargado: Yup.number()
    .min(1, 'Campo requerido')
    .required('Campo requerido'),
  id_turno: Yup.number().min(1, 'Campo requerido').required('Campo requerido'),
  id_punto_venta: Yup.number()
    .min(1, 'Campo requerido')
    .required('Campo requerido'),
});

const View: React.FC<Props> = ({}) => {
  const [listFinal, setListFinal] = useState<initList[]>([]);
  const [totalEfectivo, setTotalEfectivo] = useState<number>(0);
  const [totalTarjeta, setTotalTarjeta] = useState<number>(0);

  const [isDisabledBtnCloseDay, setIsDisabledBtnCloseDay] =
    useState<boolean>(false);

  const [row_id, setRowId] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  // GENERALES
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  // const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

  // DIA INICIADO CONST
  const [currentDayId, setCurrentDayId] = useState<number>(0);
  const [existDay, setExistDay] = useState<boolean>(false);
  const [textDay, setTextDay] = useState<string>('');

  // INICIA BUSQUEDA
  const [textBox, setTextBox] = useState<string>('');
  const [textTurn, setTextTurn] = useState<string>('');

  // BUSQUEDA
  const [userId, setUserId] = useState<number>(0);

  // OBTIENE VALIDACIÓN PARA CERRAR EL DÍA
  // const { validateCloseDay, loadingRequest: loadingCloseDay } =
  //   useGetValidateCloseDay();

  // CARGA SELECT CAJEROS
  const [selectedOptionUser, setSelectedOptionUser] = useState<any | null>(
    null
  );
  const [reloadUsers, setReloadUsers] = useState(Math.random() * 40);
  const { catalog: users, loadingRequest: loadingUsers } =
    useGetAllCashierUserSelect(reloadUsers);

  // OBTIENE CAJA Y TURNO POR CAJERO
  const [reloadInfoUser, setReloadInfoUser] = useState(Math.random() * 40);
  const { dataUser } = useGetInfoPerUser(reloadInfoUser, userId);

  // OBTIENE EL DIA ACTUAL INICIADO
  const [reloadDay, setReloadDay] = useState(Math.random() * 40);
  const { catalog: day, loadingRequest: loadingDay } =
    useGetAllDaysSelect(reloadDay);

  // OBTIENE LA INFORMACIÓN DEL CORTE
  const [reloadCut, setReloadCut] = useState(Math.random() * 40);
  const [filter, setFilter] = useState({
    id_usuario_encargado: 0,
    id_punto_venta: 0,
    id_turno: 0,
    id_dia: 0,
    id_transaccion_caja: 0,
    random: 0,
  });
  const { cut, loadingRequest: loadingCUT } = useGetInfoCut(reloadCut, filter);
  const { boxCut, loading: loadingCCUT, isSuccess } = useBoxCut();

  // LISTAR CORTES
  const [reloadCuts, setReloadCuts] = useState(Math.random() * 40);
  const { cutList, loadingRequest: loadingCLIST } =
    useGetCutStarted(reloadCuts);

  // FINALIZAR CORTE
  const {
    finishDayDelivery,
    loading: loadingFinish,
    isSuccess: isSuccessClose,
  } = useFinishDayDelivery();

  const FormSearch = useFormik({
    initialValues: {
      id_usuario_encargado: 0,
      id_punto_venta: 0,
      id_turno: 0,
      id_dia: 0,
      id_transaccion_caja: 0,
      random: 0,
    },
    validationSchema: validationSearchSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      setFilter({
        id_usuario_encargado: values.id_usuario_encargado,
        id_punto_venta: values.id_punto_venta,
        id_turno: values.id_turno,
        id_dia: values.id_dia,
        id_transaccion_caja: values.id_transaccion_caja,
        random: Math.random() * 40,
      });
    },
  });

  const Form = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      const dataCut = {
        id_punto_venta: filter.id_punto_venta,
        id_dia: filter.id_dia,
        id_turno: filter.id_turno,
        id_cajero: filter.id_usuario_encargado,
        efectivo_devuelto: totalEfectivo,
        electronico_devuelto: totalTarjeta,
        productos_devueltos: listFinal,
      };
      boxCut(dataCut);
    },
  });

  const changeUsers = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionUser(selectedOption);
      FormSearch.setFieldValue('id_usuario_encargado', value);
      setUserId(value);
    } else {
      setSelectedOptionUser(null);
      FormSearch.setFieldValue('id_usuario_encargado', 0);
      FormSearch.setFieldValue('id_punto_venta', 0);
      FormSearch.setFieldValue('id_turno', 0);
      setTextBox('');
      setTextTurn('');
      setUserId(0);
    }
  };

  const obtenerCantidadPorIdProducto = (array: any[], idProducto: number) => {
    const objetoEncontrado = array.find(
      (item) => item.producto.id === idProducto
    );
    return objetoEncontrado ? objetoEncontrado.cantidad : 0;
  };

  const columns: any = [
    {
      id: 'caja',
      name: <div className="fw-bolder text-muted me-5">Caja</div>,
      selector: (row: any) => row.punto_venta.nombre,
      sortable: true,
      width: '30%',
    },
    {
      id: 'cajero',
      name: <div className="fw-bolder text-muted me-5">Cajero</div>,
      selector: (row: any) => row.user_encargado.nombre_completo,
      sortable: true,
      width: '30%',
    },
    {
      id: 'turno',
      name: <div className="fw-bolder text-muted me-5">Turno</div>,
      selector: (row: any) => row.turno.nombre,
      sortable: true,
      width: '30%',
    },
    // {
    //   id: 'efectivo_inicial',
    //   name: <div className="fw-bolder text-muted me-5">Efectivo inicial</div>,
    //   selector: (row: any) => row.name,
    //   sortable: true,
    //   width: '25%',
    // },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => {
        return (
          <>
            <div className="text-center">
              <button
                className="btn btn-sm btn-primary fw-bold"
                onClick={() => {
                  setRowId(row.id);
                  setShowModal(!showModal);
                }}
              >
                Ver
              </button>
            </div>
          </>
        );
      },
      sortable: false,
      width: '10%',
    },
  ];
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessClose) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [isSuccessClose]);

  useEffect(() => {
    if (day) {
      const { dia, fecha_str } = day;
      if (dia) {
        setTextDay(fecha_str);
        setCurrentDayId(dia.id || 0);
        setExistDay(true);
        FormSearch.setFieldValue('id_dia', dia.id || 0);
        setIsDisabledBtnCloseDay(false);
      } else {
        FormSearch.setFieldValue('id_dia', 0);
        setTextDay('');
        setCurrentDayId(0);
        setExistDay(false);
        setIsDisabledBtnCloseDay(true);
      }
    } else {
      FormSearch.setFieldValue('id_dia', 0);
      setTextDay('');
      setCurrentDayId(0);
      setExistDay(false);
      setIsDisabledBtnCloseDay(true);
    }
  }, [day]);

  useEffect(() => {
    if (dataUser) {
      const { punto_venta, turno, id } = dataUser;
      const id_punto_venta = punto_venta ? punto_venta.id : 0;
      const id_turno = turno ? turno.id : 0;
      const id_transaccion_caja = id ? id : 0;
      const nombre_punto_venta = punto_venta ? punto_venta.nombre : '';
      const nombre_turno = turno ? turno.nombre : '';
      FormSearch.setFieldValue('id_punto_venta', id_punto_venta || 0);
      FormSearch.setFieldValue('id_turno', id_turno || 0);
      FormSearch.setFieldValue('id_transaccion_caja', id_transaccion_caja || 0);
      setTextBox(nombre_punto_venta || '');
      setTextTurn(nombre_turno || '');
    }
  }, [dataUser]);

  useEffect(() => {
    if (cut && cut.transaccion_caja) {
      if (
        cut.transaccion_caja &&
        cut.inventario_ventas &&
        cut.transaccion_caja.entrega_caja_producto
      ) {
        const totalEfectivoCalculo =
          (cut.transaccion_caja
            ? Number(cut.transaccion_caja.efectivo_apertura)
            : 0) +
          (cut.transaccion_caja.transaccion_cuenta
            ? Number(cut.transaccion_caja.transaccion_cuenta.importe_efectivo)
            : 0);
        const totalTarjetaCalculo = cut.transaccion_caja.transaccion_cuenta
          ? Number(cut.transaccion_caja.transaccion_cuenta.importe_tarjeta)
          : 0;
        setTotalTarjeta(totalTarjetaCalculo);
        setTotalEfectivo(totalEfectivoCalculo);
        const arrayVentas = cut.inventario_ventas;
        const arrayEntregado = cut.transaccion_caja.entrega_caja_producto;
        const newArray = arrayVentas.map((item: any) => ({
          id: item.producto.id,
          nombre: item.producto.nombre,
          solicitado:
            obtenerCantidadPorIdProducto(arrayEntregado, item.producto.id) -
            item.cantidad_total,
          entregado:
            obtenerCantidadPorIdProducto(arrayEntregado, item.producto.id) -
            item.cantidad_total,
          cantidad_devuelta:
            obtenerCantidadPorIdProducto(arrayEntregado, item.producto.id) -
            item.cantidad_total -
            (obtenerCantidadPorIdProducto(arrayEntregado, item.producto.id) -
              item.cantidad_total),
          tipo: 0,
        }));
        setListFinal(newArray);
      }
    }
  }, [cut]);

  const cantidadChange = (cantidad: number, id: number) => {
    setListFinal((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            entregado: cantidad,
            cantidad_devuelta: item.solicitado - cantidad,
          };
        }
        return item;
      });
      return updatedDataMemo;
    });
  };

  return (
    <>
      {currentDayId === 0 ? (
        <MensajeDia mensaje={''} />
      ) : (
        <>
          <div className="d-flex flex-column flex-xl-row">
            <div className="flex-lg-row-fluid">
              <div className="card mb-6 mb-xl-9">
                <div className="card-header border-0">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">
                      Cerrar día administrativo
                    </span>
                  </h3>
                </div>
                <div className="card-body">
                  <div className="d-flex">
                    <button
                      type="button"
                      title="Iniciar día"
                      onClick={() => {
                        Swal.fire({
                          title: '¿Desea cerrar el día administrativo?',
                          html: `
                      <div className="text-gray-600">
                        Estás a punto de cerrar el día. Esta acción registrará los cortes de caja y no podrá deshacerse.
                      </div>
                      `,
                          showCancelButton: true,
                          padding: '2em',
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Aceptar',
                          reverseButtons: true,
                          customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-light me-3',
                          },
                        }).then((result) => {
                          if (result.isConfirmed) {
                            finishDayDelivery(currentDayId);
                          }
                        });
                      }}
                      className="btn btn-danger w-200px"
                      disabled={loadingFinish || isDisabledBtnCloseDay}
                    >
                      {!loadingFinish && (
                        <span className="indicator-label">Cerrar día</span>
                      )}
                      {loadingFinish && (
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    <div className="d-flex justify-content-center flex-column ms-10">
                      <span className="fs-4 text-gray-700">{textDay}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-6 mb-xl-9">
                <div className="card-header border-0">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">
                      Búsqueda de caja para cierre
                    </span>
                  </h3>
                </div>
                <div className="card-body">
                  <form
                    id="formBoxDetail"
                    className="form"
                    action="#"
                    noValidate
                    onSubmit={FormSearch.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Cajero:{' '}
                            </label>
                            <div className="w-auto">
                              {FormSearch.touched.id_usuario_encargado &&
                                FormSearch.errors.id_usuario_encargado && (
                                  <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                    <span role="alert">
                                      {FormSearch.errors.id_usuario_encargado}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                          <Select
                            options={users}
                            value={selectedOptionUser}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={changeUsers}
                            isLoading={loadingUsers}
                            isClearable
                            backspaceRemovesValue
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Caja:{' '}
                            </label>
                            <div className="w-auto">
                              {FormSearch.touched.id_punto_venta &&
                                FormSearch.errors.id_punto_venta && (
                                  <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                    <span role="alert">
                                      {FormSearch.errors.id_punto_venta}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="w-100 d-flex">
                            <span className="mt-3 fs-6 text-gray-700">
                              {textBox || 'Seleccione un cajero'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Turno:{' '}
                            </label>
                            <div className="w-auto">
                              {FormSearch.touched.id_turno &&
                                FormSearch.errors.id_turno && (
                                  <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                    <span role="alert">
                                      {FormSearch.errors.id_turno}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="w-100 d-flex">
                            <span className="mt-3 fs-6 text-gray-700">
                              {textTurn || 'Seleccione un cajero'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <button
                            type="submit"
                            title="Buscar"
                            className="btn btn-primary w-200px"
                            disabled={loadingCUT}
                          >
                            {!loadingCUT && (
                              <span className="indicator-label">Buscar</span>
                            )}
                            {loadingCUT && (
                              <span
                                className="indicator-progress"
                                style={{ display: 'block' }}
                              >
                                Espere por favor
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {cut?.transaccion_caja &&
                cut.transaccion_caja.id &&
                cut.transaccion_caja.id > 0 && (
                  <>
                    <div className="card mb-6 mb-xl-9">
                      <div className="card-header border-0">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold text-gray-800">
                            Corte
                          </span>
                        </h3>
                        <h3 className="card-title">
                          <span className="card-label fw-bold text-gray-800">
                            Fecha administrativa:
                          </span>
                          <span className="fs-4 text-gray-700">{textDay}</span>
                        </h3>
                      </div>
                      <div className="card-body">
                        <form
                          id="formBoxDetail"
                          className="form"
                          action="#"
                          noValidate
                          onSubmit={Form.handleSubmit}
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                              <div
                                className=" border-dashed p-10 me-5 min-h-450px"
                                style={{ borderRadius: '10px' }}
                              >
                                <div className="d-flex align-items-center mb-8">
                                  <i className="fa fa-genderless text-danger fs-2 me-2"></i>
                                  <span className="fs-5 text-gray-700">
                                    Seccion inicial
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <div className="w-250px">
                                    <div className="d-flex flex-stack mb-3">
                                      <label className="form-label w-auto">
                                        Efectivo inicial:
                                      </label>

                                      <div className="text-end fw-bold fs-6 text-gray-600">
                                        {`${formatPrice(
                                          cut.transaccion_caja
                                            ? Number(
                                                cut.transaccion_caja
                                                  .efectivo_apertura
                                              )
                                            : 0,
                                          'es-MX',
                                          'MXN',
                                          2,
                                          2
                                        )}`}
                                      </div>
                                    </div>

                                    {cut.transaccion_caja
                                      .entrega_caja_producto &&
                                    cut.transaccion_caja.entrega_caja_producto
                                      .length > 0 ? (
                                      cut.transaccion_caja.entrega_caja_producto.map(
                                        (row: any, index: any) => (
                                          <>
                                            <div className="d-flex flex-stack mb-3">
                                              <label className="form-label w-auto">
                                                {row.producto.nombre ||
                                                  'Sin nombre'}
                                                {':'}
                                              </label>

                                              <div className="text-end fw-bold fs-6 text-gray-600">
                                                {row.cantidad || 0}
                                              </div>
                                            </div>
                                          </>
                                        )
                                      )
                                    ) : (
                                      <div className="d-flex flex-stack mb-3">
                                        <label className="form-label w-auto">
                                          Sin registros:
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                              <div
                                className=" border-dashed p-10 me-5 min-h-450px"
                                style={{ borderRadius: '10px' }}
                              >
                                <div className="d-flex align-items-center mb-8">
                                  <i className="fa fa-genderless text-success fs-2 me-2"></i>
                                  <span className="fs-5 text-gray-700">
                                    Seccion ventas
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <div className="w-250px">
                                    {cut.inventario_ventas &&
                                    cut.inventario_ventas.length > 0 ? (
                                      cut.inventario_ventas.map(
                                        (row: any, index: any) => (
                                          <>
                                            <div className="d-flex flex-stack mb-3">
                                              <label className="form-label w-auto">
                                                {row.producto.nombre ||
                                                  'Sin nombre'}
                                                {':'}
                                              </label>

                                              <div className="text-end fw-bold fs-6 text-gray-600">
                                                {row.cantidad_total || 0}
                                              </div>
                                            </div>
                                          </>
                                        )
                                      )
                                    ) : (
                                      <div className="d-flex flex-stack mb-3">
                                        <label className="form-label w-auto text-gray-500">
                                          Sin ventas
                                        </label>
                                      </div>
                                    )}
                                    <div className="d-flex flex-stack mb-3">
                                      <label className="form-label w-auto">
                                        Ventas efectivo:
                                      </label>

                                      <div className="text-end fw-bold fs-6 text-gray-600">
                                        {`${formatPrice(
                                          cut.transaccion_caja
                                            .transaccion_cuenta
                                            ? Number(
                                                cut.transaccion_caja
                                                  .transaccion_cuenta
                                                  .importe_efectivo
                                              )
                                            : 0,
                                          'es-MX',
                                          'MXN',
                                          2,
                                          2
                                        )}`}
                                      </div>
                                    </div>

                                    <div className="d-flex flex-stack mb-3">
                                      <label className="form-label w-auto">
                                        Ventas con tarjeta:
                                      </label>

                                      <div className="text-end fw-bold fs-6 text-gray-600">
                                        {`${formatPrice(
                                          cut.transaccion_caja
                                            .transaccion_cuenta
                                            ? Number(
                                                cut.transaccion_caja
                                                  .transaccion_cuenta
                                                  .importe_tarjeta
                                              )
                                            : 0,
                                          'es-MX',
                                          'MXN',
                                          2,
                                          2
                                        )}`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6">
                              <div
                                className=" border-dashed p-10 me-5 min-h-450px"
                                style={{ borderRadius: '10px' }}
                              >
                                <div className="d-flex align-items-center mb-8">
                                  <i className="fa fa-genderless text-primary fs-2 me-2"></i>
                                  <span className="fs-5 text-gray-700">
                                    Seccion final
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <div className="w-100">
                                    <div className="d-flex flex-stack mb-5">
                                      <label
                                        className="form-label"
                                        style={{
                                          width: '140px',
                                        }}
                                      ></label>

                                      <div
                                        className="text-end fw-bold fs-7 text-gray-600"
                                        style={{
                                          width: '120px',
                                        }}
                                      >
                                        Solicitado
                                      </div>
                                      <div
                                        className="text-end fw-bold fs-7 text-gray-600"
                                        style={{
                                          width: '120px',
                                        }}
                                      >
                                        Entregado
                                      </div>

                                      <div
                                        className="text-end fw-bold fs-7 text-gray-600"
                                        style={{
                                          width: '120px',
                                        }}
                                      >
                                        Diferencias
                                      </div>
                                    </div>

                                    {listFinal && listFinal.length > 0 ? (
                                      listFinal.map((row: any, index: any) => (
                                        <>
                                          <div className="d-flex flex-stack mb-3">
                                            <label
                                              className="form-label"
                                              style={{
                                                width: '140px',
                                              }}
                                            >
                                              {row.nombre || 'Sin nombre'}
                                              {':'}
                                            </label>

                                            <div
                                              className="text-end fw-bold fs-6 text-gray-600"
                                              style={{
                                                width: '120px',
                                              }}
                                            >
                                              {row.solicitado}
                                            </div>
                                            <label
                                              className="form-label"
                                              style={{
                                                width: '120px',
                                              }}
                                            >
                                              <NumericFormat
                                                className="form-control w-100 h-40px"
                                                onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                  }
                                                }}
                                                value={row.entregado}
                                                onBlur={(e) => {
                                                  const value = e.target.value;
                                                  if (!value) {
                                                    e.target.value = '0';
                                                    cantidadChange(0, row.id);
                                                  }
                                                }}
                                                thousandSeparator={false}
                                                allowNegative={false}
                                                decimalScale={0}
                                                suffix=""
                                                placeholder={'0'}
                                                allowLeadingZeros={false}
                                                onValueChange={(values) => {
                                                  const { floatValue } = values;
                                                  if (floatValue) {
                                                    cantidadChange(
                                                      floatValue,
                                                      row.id
                                                    );
                                                  } else {
                                                    cantidadChange(0, row.id);
                                                  }
                                                }}
                                              />
                                            </label>
                                            <div
                                              className="text-end fw-bold fs-6 text-gray-600"
                                              style={{
                                                width: '120px',
                                              }}
                                            >
                                              {row.cantidad_devuelta}
                                            </div>
                                          </div>
                                        </>
                                      ))
                                    ) : (
                                      <div className="d-flex flex-stack mb-3">
                                        <label className="form-label w-auto text-gray-500">
                                          Sin ventas
                                        </label>
                                      </div>
                                    )}

                                    <div className="d-flex flex-stack mb-3">
                                      <label
                                        className="form-label"
                                        style={{
                                          width: '140px',
                                        }}
                                      >
                                        Total efectivo:
                                      </label>

                                      <div
                                        className="text-end fw-bold fs-6 text-gray-600"
                                        style={{
                                          width: '120px',
                                        }}
                                      >
                                        {`${formatPrice(
                                          (cut.transaccion_caja
                                            ? Number(
                                                cut.transaccion_caja
                                                  .efectivo_apertura
                                              )
                                            : 0) +
                                            (cut.transaccion_caja
                                              .transaccion_cuenta
                                              ? Number(
                                                  cut.transaccion_caja
                                                    .transaccion_cuenta
                                                    .importe_efectivo
                                                )
                                              : 0),
                                          'es-MX',
                                          'MXN',
                                          2,
                                          2
                                        )}`}
                                      </div>
                                      <label
                                        className="form-label"
                                        style={{
                                          width: '120px',
                                        }}
                                      >
                                        <NumericFormat
                                          type="text"
                                          value={totalEfectivo}
                                          onValueChange={(values) => {
                                            const { floatValue } = values;
                                            if (floatValue) {
                                              setTotalEfectivo(
                                                Number(floatValue)
                                              );
                                            }
                                          }}
                                          autoComplete="off"
                                          onBlur={(e) => {
                                            const value = e.target.value;
                                            if (!value) {
                                              e.target.value = '0';
                                              setTotalEfectivo(0);
                                            }
                                          }}
                                          className="form-control w-100 h-40px"
                                          decimalScale={2}
                                          decimalSeparator="."
                                          fixedDecimalScale
                                          placeholder={'$ 0.00'}
                                          prefix={'$ '}
                                          allowNegative={false}
                                          thousandSeparator={','}
                                        />
                                      </label>
                                      <div
                                        className="text-end fw-bold fs-6 text-gray-600"
                                        style={{
                                          width: '120px',
                                        }}
                                      >
                                        {`${formatPrice(
                                          (cut.transaccion_caja
                                            ? Number(
                                                cut.transaccion_caja
                                                  .efectivo_apertura
                                              )
                                            : 0) +
                                            (cut.transaccion_caja
                                              .transaccion_cuenta
                                              ? Number(
                                                  cut.transaccion_caja
                                                    .transaccion_cuenta
                                                    .importe_efectivo
                                                )
                                              : 0) -
                                            totalEfectivo,
                                          'es-MX',
                                          'MXN',
                                          2,
                                          2
                                        )}`}
                                      </div>
                                    </div>

                                    <div className="d-flex flex-stack mb-3">
                                      <label
                                        className="form-label"
                                        style={{
                                          width: '140px',
                                        }}
                                      >
                                        Total con tarjeta:
                                      </label>

                                      <div
                                        className="text-end fw-bold fs-6 text-gray-600"
                                        style={{
                                          width: '120px',
                                        }}
                                      >
                                        {`${formatPrice(
                                          cut.transaccion_caja
                                            .transaccion_cuenta
                                            ? Number(
                                                cut.transaccion_caja
                                                  .transaccion_cuenta
                                                  .importe_tarjeta
                                              )
                                            : 0,
                                          'es-MX',
                                          'MXN',
                                          2,
                                          2
                                        )}`}
                                      </div>
                                      <label
                                        className="form-label"
                                        style={{
                                          width: '120px',
                                        }}
                                      >
                                        <NumericFormat
                                          type="text"
                                          value={totalTarjeta}
                                          onValueChange={(values) => {
                                            const { floatValue } = values;
                                            if (floatValue) {
                                              setTotalTarjeta(
                                                Number(floatValue)
                                              );
                                            }
                                          }}
                                          onBlur={(e) => {
                                            const value = e.target.value;
                                            if (!value) {
                                              e.target.value = '0';
                                              setTotalTarjeta(0);
                                            }
                                          }}
                                          autoComplete="off"
                                          className="form-control w-100 h-40px"
                                          decimalScale={2}
                                          decimalSeparator="."
                                          fixedDecimalScale
                                          placeholder={'$ 0.00'}
                                          prefix={'$ '}
                                          allowNegative={false}
                                          thousandSeparator={','}
                                        />
                                      </label>
                                      <div
                                        className="text-end fw-bold fs-6 text-gray-600"
                                        style={{
                                          width: '120px',
                                        }}
                                      >
                                        {`${formatPrice(
                                          (cut.transaccion_caja
                                            .transaccion_cuenta
                                            ? Number(
                                                cut.transaccion_caja
                                                  .transaccion_cuenta
                                                  .importe_tarjeta
                                              )
                                            : 0) - totalTarjeta,
                                          'es-MX',
                                          'MXN',
                                          2,
                                          2
                                        )}`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="d-flex justify-content-end mt-10">
                              <button
                                type="submit"
                                className="btn btn-primary ms-2"
                                data-kt-users-modal-action="submit"
                                disabled={loadingCCUT}
                              >
                                {!loadingCCUT && (
                                  <span className="indicator-label">
                                    Cerrar caja
                                  </span>
                                )}
                                {loadingCCUT && (
                                  <span
                                    className="indicator-progress"
                                    style={{ display: 'block' }}
                                  >
                                    Espere por favor...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span>
                                )}
                              </button>
                              {/* <button
                            type="button"
                            className="btn btn-primary ms-2"
                          >
                            <span className="indicator-label">Imprimir</span>
                          </button> */}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                )}
              <div className="card mb-6 mb-xl-9">
                <div className="card-header border-0">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">
                      Cortes del día
                    </span>
                  </h3>
                </div>
                <div className="card-body">
                  <TableList data={cutList} columns={columns}></TableList>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <DetailModal
        show={showModal}
        id_row={row_id}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
          } else {
            setShowModal(!showModal);
          }
        }}
      ></DetailModal>
    </>
  );
};

export { View };
