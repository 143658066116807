import React from 'react';
import { LoadingGrid, TableList, formatPrice } from '../../../../rbts/helpers';
import { useGetAllDonationsList } from '../hooks/DonationsHook';
type Props = {
  reloadGrid: number;
};

const DonationGrid: React.FC<Props> = ({ reloadGrid }) => {
  const { listDonations, loadingRequest } = useGetAllDonationsList(reloadGrid);

  const columns: any = [
    {
      id: 'desc',
      name: <div className="fw-bolder text-muted me-5">Descripción</div>,
      selector: (row: any) => row.dia,
      cell: (row: any) => (
        <>
          <span className="">
            {row.descripcion ? row.descripcion : 'No registrado'}
          </span>
        </>
      ),
      sortable: false,
      width: '20%',
    },
    {
      id: 'tipo',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
      cell: (row: any) => (
        <>
          <span>{row.tipo ? row.tipo : 'No registrado'}</span>
        </>
      ),
      sortable: false,
      width: '20%',
    },
    {
      id: 'monto',
      name: <div className="fw-bolder text-muted me-5">Monto</div>,
      selector: (row: any) => row.monto,
      cell: (row: any) => (
        <>
          <span className="text-success">
            {row.monto
              ? formatPrice(row.monto ? row.monto : 0, 'es-MX', 'MXN', 2, 2)
              : 'N/A'}
          </span>
        </>
      ),
      sortable: false,
      width: '20%',
    },
    {
      id: 'producto',
      name: <div className="fw-bolder text-muted me-5 ">Producto </div>,
      cell: (row: any) => (
        <>
          <span>{row.producto ? row.producto?.nombre : 'N/A'}</span>
        </>
      ),
      sortable: false,
      width: '20%',
    },
    {
      id: 'cantidad',
      name: <div className="fw-bolder text-muted me-5 ">Cantidad </div>,
      cell: (row: any) => (
        <>
          <span>{row.cantidad ? row.cantidad : 'No registrado'}</span>
        </>
      ),
      sortable: false,
      width: '20%',
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          {loadingRequest ? (
            <LoadingGrid text="Cargando..."></LoadingGrid>
          ) : (
            <TableList data={listDonations} columns={columns}></TableList>
          )}
        </div>
      </div>
    </>
  );
};

export { DonationGrid };
